import React, { useEffect, useState } from "react";
import ProfileBanner from "../ProfileBanner";
import coverSrc from "assets/img/cover_image.jpeg";
import avatar from "assets/img/team/26.jpeg";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Doughnut, Line } from "react-chartjs-2";
import ProfileSettings from "./ProfileSettings";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "services/axiosInstance";
import classNames from "classnames";
import paths from "routes/paths";

import { v4 as uuid } from "uuid";
import user1 from "assets/img/team/1.jpg";
import user2 from "assets/img/team/2.jpg";
import user3 from "assets/img/team/3.jpg";
import user4 from "assets/img/team/4.jpg";
import user5 from "assets/img/team/5.jpg";
import user7 from "assets/img/team/7.jpg";
import user10 from "assets/img/team/10.jpg";
import user12 from "assets/img/team/12.jpg";
import user13 from "assets/img/team/13.jpg";
import user16 from "assets/img/team/16.jpg";
import user25 from "assets/img/team/25.jpg";
import user18 from "assets/img/team/18.jpg";

import RunningProjects from "components/dashboards/default/RunningProjects";
import { runningProjects } from "data/dashboard/default";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import IconButton from "components/common/IconButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTableProvider from "providers/AdvanceTableProvider";

import { useAdvanceTableContext } from "providers/AdvanceTableProvider";
import useSupportDeskTable from "hooks/useSupportDeskTable";
import AllTicketsHeader from "components/app/support-desk/tickets-layout/AllTicketsHeader";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import CardLayout from "components/app/support-desk/tickets-layout/CardLayout";

// import ExperiencesSettings from "./ExperiencesSettings";
// import EducationSettings from "./EducationSettings";
// import AccountSettings from "./AccountSettings";
// import BillingSettings from "./BillingSettings";
// import ChangePassword from "./ChangePassword";
// import DangerZone from "./DangerZone";

const lineLabels = ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "DEC"];

const Settings = () => {
  const { customer } = useParams();
  // const getSelectedRowModel = useAdvanceTableContext();
  const [customerDataStatus, setCustomerDataStatus] = useState("idle");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [groupedSums, setGroupedSums] = useState(null);
  const [costPerCSP, setCostPerCSP] = useState({});
  const [customerPerf, setCustomerPerf] = useState([]);
  const [costPerDCStatus, setCostPerDCStatus] = useState("idle");
  const [lineData, setLineData] = useState({
    labels: lineLabels,
    datasets: [],
  });
  const [perDCCost, setPerDCCost] = useState({});
  const [lineDataStatus, setLineDataStatus] = useState("idle");
  // console.log({ getSelectedRowModel });
  const navigate = useNavigate();
  const [perServiceCost, setPerServiceCost] = useState({});
  const [totalCostDC, setTotalCostDC] = useState(0);
  const [totalServiceCost, setTotalServiceCost] = useState(0);
  const [summedService, setSummedService] = useState([]);
  const [costPerServiceStatus, setCostPerServiceStatus] = useState("idle");

  const table = useSupportDeskTable({
    perPage: 7,
    pagination: true,
  });

  useEffect(() => {
    // axiosInstance.get(`/customers/performance/navy-network`).then((res) => {
    //   setVlanPerformanceData(res.data);
    // });

    axiosInstance.get(`/customers/navy-network`).then((res) => {
      console.log({ res });
      setSelectedCustomer(res.data.customer);
    });

    setCustomerPerf([]);
    // setSelectedLocation(null);
  }, [customer]);

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#fff",
        },
      },
      title: {
        display: true,
        text: "Cost Per Data Center $K",
        color: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(255, 255, 255, 0.5)",
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.5)",
        },
        ticks: {
          color: "white", // Change color of y axis labels to white
        },
      },
    },
  };

  useEffect(() => {
    let intervalId;

    const fetchData = () => {
      axiosInstance.get(`/data-centers/navy-network`).then((res) => {
        setGroupedSums(res.data.groupedSums);
        setCostPerCSP(res.data.resp);
        const _lineData = {
          labels: lineLabels,
          datasets: res.data.datasetsForLineChart,
        };
        setLineData(_lineData);
      });

      axiosInstance
        .get(`/data-centers/cost/navy-network`)
        .then((res) => {
          setPerDCCost(res.data.transform);
          setTotalCostDC(res.data.sum);
        })
        .finally(() => {
          setCostPerDCStatus("idle");
        });

      axiosInstance
        .get(`/data-centers/cost/service/navy-network`)
        .then((res) => {
          setPerServiceCost(res.data.transform);
          setTotalServiceCost(res.data.sum);
        })
        .finally(() => {
          setCostPerServiceStatus("idle");
        });

      axiosInstance.get(`/data-centers/cost/all/navy-network`).then((res) => {
        setSummedService(res.data.vlanPerformance);
      });
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Fetch data immediately when the page becomes visible
        fetchData();
        // Start the interval for periodic fetching
        intervalId = setInterval(fetchData, 10000);
      } else {
        // Clear the interval when the page is not visible
        clearInterval(intervalId);
      }
    };

    // Add event listener for page visibility changes
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Fetch data immediately on component mount if page is visible
    if (document.visibilityState === "visible") {
      fetchData();
      intervalId = setInterval(fetchData, 10000);
    }

    // Clean up on component unmount
    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [customer]);

  const navigateToDC = (location) => {
    navigate(`/app/customers/navy-network/data-centers/${location.id}`);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: "#fff",
          font: {
            family: "poppins",
          },
        },
      },
      datalabels: {
        formatter: function (value) {
          return "$" + Math.floor(value / 1000); //for number format
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 12,
          family: "poppins",
        },
      },
    },
  };

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={avatar}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={12}>
          <ProfileSettings />
        </Col>
        <Col lg={12}>
          <div className="sticky-sidebar">
            {/* <AccountSettings />
            <BillingSettings />
            <ChangePassword />
            <DangerZone /> */}

            <Row className="g-3 mb-3 mt-4">
              {customerDataStatus === "fetching" ? (
                <Col md={3} xxl={3} mt={4}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: "200px" }}
                  >
                    <Spinner className="start-50" animation="grow" />
                  </div>
                </Col>
              ) : (
                selectedCustomer?.location?.map((location) => {
                  return (
                    <Col md={3} xxl={3} mt={4} key={location.id}>
                      <h5
                        className="mb-2 mt-2 text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigateToDC(location)}
                      >
                        {location?.name} Cost Details <br />$
                        {location?.name !== "" &&
                          groupedSums && // Ensure groupedSums is not null or undefined
                          Object.keys(groupedSums).length > 0 &&
                          new Intl.NumberFormat("en-US", {
                            notation: "compact",
                          }).format(groupedSums[location.name])}
                      </h5>

                      {typeof costPerCSP !== "undefined" &&
                        costPerCSP.hasOwnProperty(location.name) && (
                          <Doughnut
                            data={costPerCSP[location.name]}
                            plugins={[ChartDataLabels]}
                            options={options}
                          />
                        )}
                    </Col>
                  );
                })
              )}
            </Row>
          </div>
        </Col>

        <Col lg={12}>
          <Row>
            <Col md={6}>
              {lineDataStatus === "fetching" ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <Spinner className="start-50" animation="grow" />
                </div>
              ) : (
                <Line options={lineOptions} data={lineData} />
              )}
            </Col>
            {costPerDCStatus === "fetching" ? (
              <Col md={3} xxl={3} mt={4}>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <Spinner className="start-50" animation="grow" />
                </div>
              </Col>
            ) : (
              <Col md={3} xxl={3} mt={4}>
                <h5 className="mt-2 text-center">
                  Cost Per Data Center <br />${" "}
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(totalCostDC)}
                </h5>
                {Object.values(perDCCost)?.length > 0 && (
                  <Doughnut
                    data={perDCCost}
                    plugins={[ChartDataLabels]}
                    options={options}
                  />
                )}
              </Col>
            )}
            {costPerServiceStatus === "fetching" ? (
              <Col md={3} xxl={3} mt={4}>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <Spinner className="start-50" animation="grow" />
                </div>
              </Col>
            ) : (
              <Col md={3} xxl={3} mt={4}>
                <h5 className="mt-2 text-center">
                  Cost Per Service <br />${" "}
                  {new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(totalServiceCost)}
                </h5>
                {Object.values(perServiceCost)?.length > 0 && (
                  <Doughnut
                    data={perServiceCost}
                    plugins={[ChartDataLabels]}
                    options={options}
                  />
                )}
              </Col>
            )}
          </Row>
        </Col>

        <Col lg={12}>
          <RunningProjects data={runningProjects} />
        </Col>

        <Col lg={12}>
          <Col xs="auto">
            <AdvanceTableSearchBox
              className="input-search-width"
              placeholder="Search by name"
            />
            <div className="border-bottom border-200 my-3"></div>
            {/* <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="filter"
                transform="shrink-4"
                iconAlign="middle"
                // onClick={handleShow}
                className="d-xl-none"
              >
                <span className="d-none d-sm-inline-block ms-1">Filter</span>
              </IconButton>
              <div
                className="bg-300 mx-3 d-none d-lg-block d-xl-none"
                style={{ width: "1px", height: "29px" }}
              ></div>

              <div id="orders-actions">
                <Dropdown
                  align="end"
                  className="btn-reveal-trigger d-inline-block me-2"
                >
                  <Dropdown.Toggle split variant="falcon-default" size="sm">
                    <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block me-1">
                      Table view
                    </span>
                    <FontAwesomeIcon icon="chevron-down" transform="shrink-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="border py-0">
                    <div className="py-2">
                      <Link
                        className={classNames("dropdown-item")}
                        to={paths.ticketsTable}
                      >
                        Table View
                      </Link>
                      <Link
                        className={classNames("dropdown-item")}
                        to={paths.ticketsCard}
                      >
                        Card View
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  iconAlign="middle"
                >
                  <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                    New
                  </span>
                </IconButton>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  className="mx-2"
                  iconAlign="middle"
                >
                  <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                    Export
                  </span>
                </IconButton>
                <Dropdown
                  align="end"
                  className="btn-reveal-trigger d-inline-block"
                >
                  <Dropdown.Toggle split variant="falcon-default" size="sm">
                    <FontAwesomeIcon icon="ellipsis-h" className="fs-11" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="border py-0">
                    <div className="py-2">
                      <Dropdown.Item>View</Dropdown.Item>
                      <Dropdown.Item>Export</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item className="text-danger">
                        Remove
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div> */}

            <Col xxl={12} xl={12}>
              <AdvanceTableProvider {...table}>
                <Card>
                  <Card.Header className="border-bottom border-200 px-0">
                    <AllTicketsHeader
                      layout="card-view"
                      // handleShow={handleShow}
                    />
                  </Card.Header>
                  <Card.Body className="bg-body-tertiary">
                    <CardLayout />
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-center">
                    <AdvanceTablePagination />
                  </Card.Footer>
                </Card>
              </AdvanceTableProvider>
            </Col>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
