import { useEffect, useState } from "react";
import NetworkMaps from "../customer-multi-clouds/NetworkMaps";
import { useParams } from "react-router-dom";
import axiosInstance from "services/axiosInstance";
import axios from "axios";

const DataCenter = () => {
  const { customer, data_center } = useParams();
  const [loading, setLoading] = useState(false);
  const [customerPerf, setCustomerPerf] = useState([]);
  const [vlanPerformanceData, setVlanPerformanceData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/customers/performance/${customer}`)
      .then((res) => {
        setVlanPerformanceData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });

    axiosInstance
      .get(`/customers/performance/${data_center}/location`)
      .then((res) => {
        setCustomerPerf(res.data.vlanPerformance);
      });
  }, [customer, data_center]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const interval = setInterval(() => {
      if (data_center) {
        axiosInstance
          .get(`/customers/performance/${data_center}/location`, {
            signal,
          })
          .then((res) => {
            setCustomerPerf(res.data.vlanPerformance);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request canceled", err.message);
            } else {
              console.error("Other error", err);
            }
          });
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [data_center, loading]);

  useEffect(() => {
    let isComponentActive = true; // Flag to track component activity

    // Fetch data function
    const fetchData = () => {
      console.log("fetchData calkled");

      if (!isComponentActive) return; // Avoid calling fetchData if the component is inactive

      axiosInstance
        .get(`/customers/${customer}`)
        // .then((res) => {
        //   if (res.data && res.data.vlanPerformance) {
        //     setSummedService(res.data.vlanPerformance);
        //   }
        // })
        .catch((err) => {
          console.error('Error fetching data', err);
        });
    };

    // Send execute=false function
    const sendExecuteFalse = () => {
      axiosInstance
        .get(`/customers/${customer}?execute=false`)
        .then(() => {
          console.log('API called with execute=false');
        })
        .catch((err) => {
          console.error('Error sending execute=false', err);
        });
    };

    // Call functions initially and then every 8 seconds
    const intervalId = setInterval(() => {
      if (isComponentActive) {
        fetchData(); // Fetch data every 8 seconds
        // sendExecuteFalse(); // Send execute=false every 8 seconds
      }
    }, 8000); // 8 seconds interval

    // Initial call to ensure functions are executed immediately when the component mounts
    fetchData();
    // sendExecuteFalse();

    // Handle page unload or tab close
    const handleBeforeUnload = (event) => {
      isComponentActive = false; // Set the flag to inactive
      sendExecuteFalse(); // Ensure sendExecuteFalse is called during unload
      event.preventDefault();
      event.returnValue = ''; // Ensures the prompt appears in some browsers
    };

    // Add event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup on component unmount or page navigation
    return () => {
      isComponentActive = false; // Ensure flag is inactive on cleanup
      clearInterval(intervalId); // Clear the interval to stop repeated calls
      sendExecuteFalse();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [customer]);

  console.log({ vlanPerformanceData });

  return (
    <NetworkMaps
      loading={loading}
      vlanData={vlanPerformanceData}
      customerPerf={customerPerf}
    />
  );
};

export default DataCenter;
