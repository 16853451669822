import paths from "routes/paths";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Divider from "components/common/Divider";
import axiosInstance from "services/axiosInstance";
import SocialAuthButtons from "./SocialAuthButtons";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useAuthWizardContext } from "providers/AuthWizardProvider";

const forgotPasswordPaths = {
  simple: paths.simpleForgotPassword,
  split: paths.splitForgotPassword,
  card: paths.cardForgotPassword,
};

const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const { setUser, user } = useAuthWizardContext();
  const navigate = useNavigate();
  const [status, setStatus] = useState("idle");

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");

    axiosInstance
      .post("/auth/login", {
        email: formData.email,
        password: formData.password,
      })
      .then((res) => {
        localStorage.clear("user-id");
        localStorage.setItem("user-id", res.data.user.id);
        navigate("/app/customers/navy-network");
        setUser({
          ...user,
          ...{ id: res.data.user.id, role: res.data.user.role },
        });
        toast.success(res.data.message, {
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      })
      .finally(() => {
        setStatus("idle");
      });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Password" : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link className="fs-10 mb-0" to={forgotPasswordPaths[layout]}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          {status === "loading" ? (
            <Spinner
              className="mt-1"
              animation="border"
              role="status"
              size="sm"
            />
          ) : (
            "Log in"
          )}
        </Button>
      </Form.Group>

      <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons />
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
