import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Spinner } from "react-bootstrap";
import axiosInstance from "services/axiosInstance";

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("idle");

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus("loading");

    axiosInstance
      .post("/auth/forgot-password", {
        email,
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      })
      .finally(() => {
        setStatus("idle");
      });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={"Email address"}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button
          className="w-100"
          type="submit"
          disabled={!email || status === "loading"}
        >
          {status === "loading" ? (
            <Spinner
              className="mt-1"
              animation="border"
              role="status"
              size="sm"
            />
          ) : (
            "Send reset link"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string,
};

ForgetPasswordForm.defaultProps = { layout: "simple" };

export default ForgetPasswordForm;
