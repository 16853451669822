import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Logo = ({ at, className, ...rest }) => {
  return (
    <Link
      to="/app/customers/navy-network"
      className={classNames(
        "text-decoration-none",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center fw-bolder fs-4 mb-4": at === "auth",
          },
          className
        )}
      >
        {/* <img className="me-2" src={logo} alt="Logo" width={width} /> */}
        <span
          className={classNames("font-sans-serif")}
          style={{ color: "#fff" }}
        >
          DELL
        </span>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
  width: PropTypes.number,
  className: PropTypes.string,
  textclass: PropTypes.string,
};

Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;
