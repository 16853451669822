import PropTypes from "prop-types";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Divider from "components/common/Divider";
import axiosInstance from "services/axiosInstance";
import SocialAuthButtons from "./SocialAuthButtons";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useAuthWizardContext } from "providers/AuthWizardProvider";

const RegistrationForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAccepted: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("idle");

  const { setUser, user } = useAuthWizardContext();
  const navigate = useNavigate();

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus("loading");
    axiosInstance
      .post("/auth/registration", {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      })
      .then((res) => {
        // localStorage.setItem('user-id', JSON.stringify(res.data.user.id));
        // navigate('/app/dell-ainc-admin');
        // setUser({
        //   ...user,
        //   ...{ id: res.data.user.id, role: res.data.user.role }
        // });
        toast.success(res.data.message, {
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      })
      .finally(() => {
        setStatus("idle");
      });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Name" : ""}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Password" : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? "Confirm Password" : ""}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={(e) =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked,
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{" "}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.isAccepted ||
            status === "loading"
          }
        >
          {status === "loading" ? (
            <Spinner
              className="mt-1"
              animation="border"
              role="status"
              size="sm"
            />
          ) : (
            "Register"
          )}
        </Button>
      </Form.Group>
      <Divider>or register with</Divider>

      <SocialAuthButtons />
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default RegistrationForm;
