import { useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import axiosInstance from "services/axiosInstance";

const Verify = () => {
  const [searchParams] = useSearchParams();

  // Get the token value
  const token = searchParams.get('token');

  useEffect(() => {
    axiosInstance.get(`/auth/verification?token=${token}`)
      .then(res => {
        console.log({ res});
      })
  }, [])

  return (
    <div>
      <h3>Congrats, your account is successfully verified</h3>

      <Link to="/authentication/simple/login">Go to Login</Link>


    </div>
  )
}

export default Verify;
